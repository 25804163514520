import React, { useState } from "react";
import styles from "./TicketEditHeaderVisibilityEdit.module.scss";
import { ReactComponent as IconPencilEdit } from "assets/icons/pencil-edit.svg"
import { ReactComponent as IconClose} from "assets/icons/close.svg"
import { VisibilityLabel, VisibilityToggle } from "components/visibility-toggle/VisibilityToggle";
import { ReactComponent as IconCheck } from "assets/icons/check.svg"
import { datadogLogs } from "@datadog/browser-logs";
import { VisibilityStatusEnum } from "appConstants";

interface IInlineEditProps extends Partial<React.FormEvent<HTMLDivElement>> {
    onAccept: (text: VisibilityStatusEnum) => Promise<any>;
    visibility: VisibilityStatusEnum;
};

export const TicketEditHeaderVisibilityEdit: React.FC<IInlineEditProps>= ({onAccept, visibility}: IInlineEditProps) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleAccept = async() => {
        try {
            setIsLoading(true);
            await onAccept(visibility);
            handleCancel();
        } catch(e) {
            if (!(e instanceof Error)) throw e;
            datadogLogs.logger.error("TicketEditHeaderVisibilityEdit.handleAccept", {}, e);
        }
        setIsLoading(false);
    };

    const handleCancel = () => {
        setIsEdit(!isEdit);
    };

    const handleChange = (newVisibility: VisibilityStatusEnum) => {
        visibility = newVisibility;
    };

    return (
        <div className={styles.container}>
            <b>Visibility:</b>
            {isEdit ?
                <div>
                    <VisibilityToggle className={styles["visibility-toggle"]} disabled={isLoading} onUpdate={handleChange} defaultVisibility={visibility}/>
                    <div className={styles.actions}>
                        <button data-testid="visibility-edit-close-btn" disabled={isLoading} className="btn-chip" onClick={handleCancel}>
                            <IconClose className="icon-bg" />
                        </button>
                        <button disabled={isLoading} data-testid="visibility-edit-save-btn" className="btn-chip" onClick={handleAccept}>
                            <IconCheck className="icon-bg" />
                        </button>
                    </div>
                </div>
                : 
                <div className={styles.label}>
                    <VisibilityLabel data-testid="visibility-edit-label" className={styles["visibility-label"]} defaultVisibility={visibility} />
                    <button data-testid="visibility-edit-edit-btn" className="btn-chip" onClick={() => setIsEdit(!isEdit)}>
                        <IconPencilEdit className="icon-bg" />
                    </button>
                </div>
            }
        </div>
    );
};
